import React, { useState } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";
// import Tooltip from "recharts/es6/component/Tooltip";

import Grid from "@mui/material/Grid";
// import ResponsiveContainer from "recharts/es6/component/ResponsiveContainer";
import SignIcon from "../icons/SignIcon";

import ObjectIcon from "../icons/ObjectIcon";
import {
  ASC,
  CONJUNCTION,
  DESC,
  DYNAMIC_NATURE,
  HARMONIC_NATURE,
  IC,
  MC,
  NEUTRAL_NATURE,
  NO_NATURE,
  RETROGRADE
} from "../../utils/constants";
import { elementColors } from "assets/jss/rootStyle";
import RetrogradeIcon from "../icons/RetrogradeIcon";
import AspectIcon from "../icons/AspectIcon";

const aspectColors = {
  [HARMONIC_NATURE]: elementColors.aqua.bg,
  [NEUTRAL_NATURE]: elementColors.earth.bg,
  [DYNAMIC_NATURE]: elementColors.fire.bg,
  [NO_NATURE]: elementColors.earth.bg
};

const opacityTransitionStyle = {
    "-webkit-transition": "opacity 0.4s ease-in-out",
    "-moz-transition": "opacity 0.4s ease-in-out",
    "-ms-transition": "opacity 0.4s ease-in-out",
    "-o-transition": "opacity 0.4s ease-in-out",
    transition: "opacity 0.4s ease-in-out"
  };

const preventDefault = (f, v) => e => {
  e.stopPropagation();
  return f(v);
};

const RADIAN = Math.PI / 180;
export default function NatalChart({
  onSelectSign,
  onSelectPlanet,
  chart,
  selectedPlanet,
  onUnselect
}) {
  const [state, setState] = useState({ activeIndex: 0, anim: false });
  const chartScale = 1.0;
  const planetIconSize = 20 * chartScale;
  const aspectIconSize = planetIconSize * 0.66;
  const signsAngle = 180 - chart.houses[0].lon;
  const hiddenAspects = [CONJUNCTION];
  const showFourAnglesLabels = true;
  const aspectsData = chart.aspects;
  const planetsData = chart.objects;
  const maxRadius = 266.63;
  const minAspectDistance = 8;

  // Outer Radius Pertentajes and Size
  const signOuterRadius = 91;
  const signRadiusWidth = 10;
  const planetsOuterRadius = signOuterRadius - 12;
  const planetsRadiusWidth = 0.5;
  const houseOuterRadius = planetsOuterRadius - 30;
  const houseRadiusWidth = 6;

  const aspectsOuterRadius = houseOuterRadius - 10;

  const getPlanetSize = (outerRadius, outerRadiusPercent) => {
    const radius = (outerRadius * signOuterRadius) / outerRadiusPercent;
    return (planetIconSize * radius) / maxRadius;
  };

  const getHousesData = () => {
    return chart.houses.map((h, i) => {
      const lon = h.lon;
      const nextLon = chart.houses[(i + 1) % 12].lon;
      const value = (nextLon - lon + 360) % 360;
      return { ...h, value: value, name: `Casa ${h.id}` };
    });
  };
  const houses = getHousesData();
  let lastPlanetLong = null;
  const planetOffetAngles = {};
  const getLastRelatedOffset = id => {
    let i = planetsData.findIndex(obj => obj.id === id);
    let offset = planetOffetAngles[planetsData[i].id];
    i = (i + 1) % planetsData.length;
    while (planetOffetAngles[planetsData[i].id] && offset < 10) {
      offset = planetOffetAngles[planetsData[i].id];
      i = (i + 1) % planetsData.length;
    }
    return offset;
  };

  const getMiddleXY = (angle1, angle2, radius, cx, cy) => {
    const { x: x1, y: y1 } = getXY(radius, angle1, cx, cy);
    const { x: x2, y: y2 } = getXY(radius, angle2, cx, cy);
    return { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
  };

  const getLineFromAngles = (angle1, angle2, radius, cx, cy) => {
    const sin1 = Math.sin(-RADIAN * angle1);
    const cos1 = Math.cos(-RADIAN * angle1);
    const sin2 = Math.sin(-RADIAN * angle2);
    const cos2 = Math.cos(-RADIAN * angle2);
    const sx = cx + radius * cos1;
    const sy = cy + radius * sin1;
    const mx = cx + radius * cos2;
    const my = cy + radius * sin2;
    return `M${sx},${sy}L${mx},${my}`;
  };

  /**
   * Returns the position where every aspect icon should be rendered, avoiding superposition and duplication of icons.
   **/
  const getAspectsPosFrames = (objId, aspects, radius, planetAngle, cx, cy) => {
    if (aspects.length === 0) return null;
    const aspectsFrames = aspects.map(asp => {
      const obj2 = objId === asp.passive.id ? asp.active.id : asp.passive.id;
      const aspPos = getMiddleXY(
        planetAngle,
        getPlanetAngle(obj2),
        radius,
        cx,
        cy
      );
      return {
        pos: aspPos,
        render: false,
        type: asp.type,
        obj: obj2,
        lon: getPlanetAngle(obj2)
      };
    });
    if (aspects.length === 1) {
      aspectsFrames[0].render = true;
      return { [aspectsFrames[0].obj]: aspectsFrames[0] };
    }
    aspectsFrames.sort((f1, f2) => f1.lon - f2.lon);
    let initialFrameIndex = 0;
    let lastFrameIndex = 0;
    let initialFrame = aspectsFrames[0];
    while (true) {
      const currentIndex = (lastFrameIndex + 1) % aspects.length;
      const currentFrame = aspectsFrames[currentIndex];
      if (
        currentFrame.type === initialFrame.type &&
        currentFrame != initialFrame &&
        Math.abs(currentFrame.lon - initialFrame.lon) % 360 < minAspectDistance
      ) {
        lastFrameIndex = currentIndex;
      } else {
        const lastFrame = aspectsFrames[lastFrameIndex];
        lastFrame.render = false;
        initialFrame.pos.x = (initialFrame.pos.x + lastFrame.pos.x) / 2;
        initialFrame.pos.y = (initialFrame.pos.y + lastFrame.pos.y) / 2;
        initialFrame.render = true;
        if (lastFrameIndex >= 0 && lastFrameIndex > currentIndex) break;
        initialFrame = currentFrame;
        initialFrameIndex = currentIndex;
        lastFrameIndex = currentIndex;
      }
    }
    return aspectsFrames.reduce((frames, frame) => {
      frames[frame.obj] = frame;
      return frames;
    }, {});
  };
  const renderAspectsIcons = (objId, aspectRadius, planetAngle, cx, cy) => {
    const aspects = aspectsData.filter(
      asp => asp.active.id === objId || asp.passive.id === objId
    );
    const posFrames = getAspectsPosFrames(
      objId,
      aspects,
      aspectRadius,
      planetAngle,
      cx,
      cy
    );
    return aspects.map(asp => {
      const AspIcon = AspectIcon[asp.type];
      const obj2 = objId === asp.passive.id ? asp.active.id : asp.passive.id;
      const frame = posFrames[obj2];
      const highlighted =
        objId === selectedPlanet &&
        (selectedPlanet === asp.active.id || selectedPlanet === asp.passive.id);
      return (
        !hiddenAspects.includes(asp.type) &&
        frame.render && (
          <AspIcon
            style={opacityTransitionStyle}
            x={frame.pos.x - aspectIconSize / 2}
            y={frame.pos.y - aspectIconSize / 2}
            fill={"#666666"}
            width={aspectIconSize * chartScale}
            height={aspectIconSize * chartScale}
            opacity={highlighted ? 1 : 0}
          />
        )
      );
    });
  };
  const renderAspects = (objId, aspectRadius, planetAngle, cx, cy) => {
    const aspects = getAspects(objId);
    return aspects.map(asp => {
      const aspectColor = aspectColors[chart.getAspectNature(asp.type)];
      const highlighted =
        selectedPlanet === asp.active.id || selectedPlanet === asp.passive.id;
      const planet2Angle = getPlanetAngle(asp.passive.id);
      return (
        <>
          <path
            key={asp.passive.id}
            d={getLineFromAngles(
              planetAngle,
              planet2Angle,
              aspectRadius,
              cx,
              cy
            )}
            stroke={aspectColor}
            fill="none"
            strokeWidth={1}
            style={opacityTransitionStyle}
            opacity={
              !selectedPlanet || (selectedPlanet && highlighted) ? 1 : 0.1
            }
          />
        </>
      );
    });
  };
  const renderSignLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    id,
    element
  }) => {
    const planetIconSize = getPlanetSize(outerRadius, signOuterRadius);
    const planets = planetsData.filter(planet => planet.sign === id);

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const iconSize = 14;
    const x =
      cx + radius * Math.cos(-midAngle * RADIAN) - (iconSize * chartScale) / 2;
    const y =
      cy + radius * Math.sin(-midAngle * RADIAN) - (iconSize * chartScale) / 2;
    const Icon = SignIcon[id];
    const houseRadius = (outerRadius * houseOuterRadius) / signOuterRadius;
    const planetOuterRadius =
      (outerRadius * planetsOuterRadius) / signOuterRadius;
    const planetRadius = houseRadius + (planetOuterRadius - houseRadius) / 2;
    const aspectRadius =
      (outerRadius * (houseOuterRadius - houseRadiusWidth)) / signOuterRadius -
      planetIconSize / 2;
    const planetLineRadius =
      (outerRadius * (planetsOuterRadius - planetsRadiusWidth)) /
      signOuterRadius;

    const getLine = (startRadius, length, angle) => {
      const sin = Math.sin(-RADIAN * angle);
      const cos = Math.cos(-RADIAN * angle);
      const sx = cx + startRadius * cos;
      const sy = cy + startRadius * sin;
      const mx = cx + (startRadius + length) * cos;
      const my = cy + (startRadius + length) * sin;
      return `M${sx},${sy}L${mx},${my}`;
    };

    // calculates only if planet id not in planetOffetAngles dict as key
    if (Object.keys(planetOffetAngles).length < planetsData.length) {
      planets.reduce((offsets, planet) => {
        const planetAngle = midAngle - 15 + parseFloat(planet.signlon);
        const planetOffsetAngle = lastPlanetLong
          ? lastPlanetLong + Math.max(planetAngle - lastPlanetLong, 7)
          : planetAngle;
        lastPlanetLong = planetOffsetAngle;
        offsets[planet.id] = planetOffsetAngle - planetAngle;
        return offsets;
      }, planetOffetAngles);
    }

    return (
      <>
        {planets.map((planet, i) => {
          const planetAngle = midAngle - 15 + parseFloat(planet.signlon);
          const planetOffsetAngle =
            planetAngle +
            planetOffetAngles[planet.id] -
            getLastRelatedOffset(planet.id) / 2;
          //lastPlanetLong = planetOffsetAngle;
          const clickablePos = getXY(planetRadius, planetOffsetAngle, cx, cy);
          return (
            <>
              {renderAspects(planet.id, aspectRadius, planetAngle, cx, cy)}
              {renderAspectsIcons(planet.id, aspectRadius, planetAngle, cx, cy)}
              {![ASC, MC, DESC, IC].includes(planet.id) && (
                <circle
                  cx={clickablePos.x}
                  cy={clickablePos.y}
                  r={planetIconSize / 2}
                  fill={"white"}
                  stroke="none"
                  cursor={"pointer"}
                  onClick={preventDefault(onSelectPlanet, planet.id)}
                />
              )}
              <path
                d={getLine(planetLineRadius - 7, 7 * chartScale, planetAngle)}
                stroke={"#666666"}
                fill="none"
                strokeWidth={1}
              />
              <path
                d={getLine(aspectRadius, planetIconSize / 2, planetAngle)}
                stroke={"#666666"}
                fill="none"
                strokeWidth={1}
              />
            </>
          );
        })}
        {
          <Icon
            x={x}
            y={y}
            fill={elementColors[element].primary}
            width={iconSize * chartScale}
            height={iconSize * chartScale}
          />
        }
      </>
    );
  };
  const getAspects = id => {
    return aspectsData.filter(asp => asp.active.id === id);
  };

  const getSignStartAngle = signId => {
    const index = chart.signs.findIndex(sign => sign.id === signId);
    return signsAngle + index * 30;
  };
  const getPlanetAngle = planetId => {
    const planet = planetsData.find(planet => planet.id === planetId);
    return getSignStartAngle(planet.sign) + parseFloat(planet.signlon);
  };
  const getXY = (radius, angle, cx, cy) => {
    const sin = Math.sin(-RADIAN * angle);
    const cos = Math.cos(-RADIAN * angle);
    return { x: cx + radius * cos, y: cy + radius * sin };
  };
  const getPolarLine = (startRadius, length, angle, cx, cy) => {
    const { x: sx, y: sy } = getXY(startRadius, angle, cx, cy);
    const { x: mx, y: my } = getXY(startRadius + length, angle, cx, cy);
    return `M${sx},${sy}L${mx},${my}`;
  };
  const renderPlanetsLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    element,
    id
  }) => {
    const getLine = (startRadius, length, angle) => {
      const sin = Math.sin(-RADIAN * angle);
      const cos = Math.cos(-RADIAN * angle);
      const sx = cx + startRadius * cos;
      const sy = cy + startRadius * sin;
      const mx = cx + (startRadius + length) * cos;
      const my = cy + (startRadius + length) * sin;
      return `M${sx},${sy}L${mx},${my}`;
    };

    const planets = planetsData.filter(
      planet => planet.sign === id && ![ASC, MC, DESC, IC].includes(planet.id)
    );
    const getPolarPosition = (cx, cy, radius, angle) => {
      const x = cx + radius * Math.cos(-angle * RADIAN);
      const y = cy + radius * Math.sin(-angle * RADIAN);
      return { x, y };
    };
    const planetIconSize = getPlanetSize(outerRadius, planetsOuterRadius);
    const houseRadius = (outerRadius * houseOuterRadius) / planetsOuterRadius;
    const planetRadius = houseRadius + (outerRadius - houseRadius) / 2;
    const angleLabelRadius = planetRadius + 1.2 * planetIconSize;
    const aspectRadius =
      (outerRadius * (houseOuterRadius - houseRadiusWidth)) /
      planetsOuterRadius;
    return (
      <>
        {Array(30)
          .fill(0)
          .map((v, i) => (
            <path
              key={i}
              d={getLine(outerRadius - 1, 4, midAngle - 15 + i)}
              stroke={"#aaaaaa"}
              //stroke={elementColors[element].bg}
              strokeWidth={1}
              fill="none"
            />
          ))}
        {[0, 1, 2, 3].map(i => (
          <path
            key={i}
            d={getLine(outerRadius - 1, 7, midAngle - 15 + i * 10)}
            stroke={"#aaaaaa"}
            //stroke={elementColors[element].bg}
            strokeWidth={2}
            fill="none"
          />
        ))}
        {planets.map(planet => {
          const planetAngle = midAngle - 15 + parseFloat(planet.signlon);
          const planetOffsetAngle =
            planetAngle +
            planetOffetAngles[planet.id] -
            getLastRelatedOffset(planet.id) / 2;
          const Icon = ObjectIcon[planet.id];
          const planetPos = getXY(planetRadius, planetOffsetAngle, cx, cy);
          if (planet.id === "Sun") {
            console.log(
              planet.id,
              planetAngle,
              planetOffetAngles[planet.id],
              getLastRelatedOffset(planet.id)
            );
          }
          const angleLabelPos = getXY(
            angleLabelRadius,
            planetOffsetAngle,
            cx,
            cy
          );
          const cirlePos = getPolarPosition(cx, cy, aspectRadius, planetAngle);
          const isRetrograde = planet.movement === RETROGRADE;
          return (
            <>
              <Icon
                key={planet.id}
                x={planetPos.x - planetIconSize / 2}
                y={planetPos.y - planetIconSize / 2}
                fill={elementColors[element].primary}
                width={planetIconSize}
                height={planetIconSize}
                cursor={"pointer"}
                onClick={preventDefault(onSelectPlanet, planet.id)}
              />
              {isRetrograde && (
                <RetrogradeIcon
                  x={planetPos.x + planetIconSize / 2.5}
                  y={planetPos.y + planetIconSize / 3.5}
                  width={planetIconSize / 2}
                  height={planetIconSize / 2}
                />
              )}
              <text
                x={angleLabelPos.x}
                y={angleLabelPos.y}
                stroke={"white"}
                strokeWidth={3}
                fontSize={10}
                textAnchor={"middle"}
                dominantBaseline="middle"
              >
                {Math.round(parseFloat(planet.signlon))}°
              </text>
              <text
                x={angleLabelPos.x}
                y={angleLabelPos.y}
                fill={"#666666"}
                fontSize={10}
                textAnchor={"middle"}
                dominantBaseline="middle"
              >
                {Math.round(parseFloat(planet.signlon))}°
              </text>
            </>
          );
        })}
      </>
    );
  };

  const renderHouseLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
    maxRadius,
    value,
    startAngle,
    signlon
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const labelAngleRadius = outerRadius + 10;
    const signInnerRadius =
      (outerRadius * (signOuterRadius - signRadiusWidth)) / houseOuterRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const labels = { 0: "AC", 3: "IC", 6: "DC", 9: "MC" };
    const labelPos = getXY(radius, startAngle, cx, cy);
    const labelsOnClick = {
      0: () => onSelectPlanet(ASC),
      3: () => null,
      6: () => null,
      9: () => onSelectPlanet(MC)
    };
    const angleLabelPos = getXY(labelAngleRadius, startAngle + 3, cx, cy);
    return (
      <>
        {[0, 3, 6, 9].includes(index) && (
          <>
            <path
              d={getPolarLine(
                innerRadius,
                signInnerRadius - innerRadius,
                startAngle,
                cx,
                cy
              )}
              stroke={"#666666"}
              fill="none"
              strokeWidth={3}
            />
            <text
              x={labelPos.x}
              y={labelPos.y}
              stroke={"white"}
              strokeWidth={4}
              textAnchor={"middle"}
              dominantBaseline="middle"
              fontSize={10}
              onClick={preventDefault(labelsOnClick[index])}
              cursor={[0, 9].includes(index) ? "pointer" : "inherit"}
            >
              {labels[index]}
            </text>
            <text
              x={labelPos.x}
              y={labelPos.y}
              stroke={"#666666"}
              strokeWidth={1}
              textAnchor={"middle"}
              dominantBaseline="middle"
              fontSize={10}
              onClick={preventDefault(labelsOnClick[index])}
              cursor={[0, 9].includes(index) ? "pointer" : "inherit"}
            >
              {labels[index]}
            </text>
          </>
        )}
        {![0, 3, 6, 9].includes(index) && (
          <path
            d={getPolarLine(
              outerRadius,
              signInnerRadius - outerRadius,
              startAngle,
              cx,
              cy
            )}
            stroke={"#aaaaaa"}
          />
        )}

        <text
          x={angleLabelPos.x}
          y={angleLabelPos.y}
          fontSize={10}
          fill="#666666"
          textAnchor={"middle"}
          dominantBaseline="middle"
        >
          {Math.round(parseFloat(signlon))}°
        </text>
        <text
          x={x}
          y={y}
          fontSize={10}
          fill="#666666"
          textAnchor={"middle"}
          dominantBaseline="middle"
        >
          {name.split(" ")[1]}
        </text>
      </>
    );
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justify={"center"}
      sx={{overflow: "hidden"}}
    >
      <Grid
        item
        xs={12}
        md={true}
        style={{
          height: "0",
          marginTop: "50%",
          paddingBottom: "50%",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div style={{ width: "100%", height: "600px" }} onClick={onUnselect}>
          <ResponsiveContainer width={"100%"} height={600}>
            <PieChart>
              {true && (
                <Pie
                  data={houses}
                  dataKey="value"
                  cx={"50%"}
                  cy={"50%"}
                  startAngle={-180}
                  endAngle={180}
                  innerRadius={`${houseOuterRadius - houseRadiusWidth}%`}
                  outerRadius={`${houseOuterRadius}%`}
                  fill="#cccccc"
                  label={renderHouseLabel}
                  labelLine={false}
                  isAnimationActive={state.anim}
                >
                  {houses.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={"white"}
                      stroke={"#aaaaaa"}
                      strokeWidth={1}
                      cursor={"pointer"}
                    />
                  ))}
                </Pie>
              )}
              {true && (
                <Pie
                  data={chart.signs}
                  dataKey="value"
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={`${signOuterRadius - signRadiusWidth}%`}
                  outerRadius={`${signOuterRadius}%`}
                  startAngle={0 + signsAngle}
                  endAngle={360 + signsAngle}
                  fill="#82ca9d"
                  //activeIndex={state.activeIndex}
                  //activeShape={this.renderActiveShape}
                  onClick={({ id }) => onSelectSign(id)}
                  //onMouseEnter={onPieEnter}
                  label={renderSignLabel}
                  labelLine={false}
                  //shape={this.zodiacSignShape}
                  tooltip={false}
                  isAnimationActive={state.anim}
                  onAnimationEnd={() => {
                    setState({ ...state, anim: false });
                  }}
                >
                  {chart.signs.map((entry, index) => (
                    <Cell
                      key={`cellf-${index}`}
                      cursor={"pointer"}
                      fill={elementColors[entry.element].bg}
                      stroke={"none"}
                    />
                  ))}
                </Pie>
              )}
              {true && (
                <Pie
                  data={chart.signs}
                  dataKey="value"
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={`${planetsOuterRadius - planetsRadiusWidth}%`}
                  outerRadius={`${planetsOuterRadius}%`}
                  startAngle={0 + signsAngle}
                  endAngle={360 + signsAngle}
                  fill="#82ca9d"
                  activeIndex={state.activeIndex}
                  //activeShape={renderActiveShapeCenter}
                  //onMouseEnter={onPieEnter}
                  label={renderPlanetsLabel}
                  labelLine={false}
                  isAnimationActive={state.anim}
                >
                  {chart.signs.map((entry, index) => (
                    <Cell
                      key={`cellff-${index}`}
                      cursor={"pointer"}
                      //fill={elementColors[entry.element].bg}
                      fill={"#aaaaaa"}
                      stroke={"none"}
                    />
                  ))}
                </Pie>
              )}
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Grid>
    </Grid>
  );
}
