import React from "react";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material";

const StyledIconButton = styled(IconButton)(({ theme, background }) => ({
  backgroundColor: background ? "#d7dcf7" : "transparent",
  border: background ? "1px solid #d7dcf7" : "1px solid transparent",
}));

export default function SvgButton({ icon, background, ...props }) {
  return (
    <StyledIconButton
      size="small"
      color={"primary"}
      background={background}
      {...props}
    >
      <SvgIcon component={icon} viewBox={"0 0 512 512"} fontSize="inherit" />
    </StyledIconButton>
  );
}
