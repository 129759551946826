import React from "react";
import Grid from "@mui/material/Grid";

import { Container, Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import { ReactComponent as IGIcon } from "assets/icons/social/ig.svg";
import styled from "@emotion/styled";

const FooterSection = styled('footer')(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  minHeight: "120px",
  display: "flex",
  color: "#dddddd"
}));

const SocialSection = styled(Box)(({theme}) => ({
  textAlign: "center",
  "& img": {
    width: "30px",
    height: "30px",
    filter: "invert(100%)"
  }
}));

export default function Footer() {

  return (
    <FooterSection>
      <Container maxWidth={"md"}>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SocialSection>
                <Link
                href="https://www.instagram.com/astrall.cl/"
                target="_blank"
              >
                <SvgIcon
                  component={IGIcon}
                  viewBox={"0 0 512 512"}
                  style={{ color: "white", width: "30px", height: "30px" }}
                />
              </Link>
              </SocialSection>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </FooterSection>
  );
}
