import Grid from "@mui/material/Grid";
import React from "react";
import {styled} from "@mui/material";
import SignIcon from "../../icons/SignIcon";
import ObjectIcon from "../../icons/ObjectIcon";
import useObjects from "../../../hooks/astrology/useObjects";
import { RETROGRADE } from "../../../utils/constants";
import RetrogradeIcon from "../../icons/RetrogradeIcon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";

const StyledPlanetRow = styled(Grid)(({ theme, selected }) => ({
    fontSize: "12px",
    "-webkit-transition": "background-color 0.4s ease-in-out",
    "-moz-transition": "background-color 0.4s ease-in-out",
    "-ms-transition": "background-color 0.4s ease-in-out",
    "-o-transition": "background-color 0.4s ease-in-out",
    transition: "background-color 0.4s ease-in-out",
    backgroundColor: selected ? "#d7dcf7" : "transparent",
  }));

const aspectIconStyle = {
    height: 12,
    width: 12,
    marginRight: 5
  };

const textRightStyle = {
    textAlign: "right",
    flexGrow: 0,
    whiteSpace: "nowrap"
  };
export default function PlanetTable({ chart, selectedPlanet }) {
  const objects = useObjects();
  return (
    <Box mb={2}>
      <Card>
        <CardContent>
          <Grid container justify={"space-between"}>
            <Grid item xs={4}>
              <Typography variant={"h6"}>Planeta</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant={"h6"}>Signo</Typography>
            </Grid>
            <Grid item xs sx={textRightStyle}>
              <Typography variant={"h6"}>Casa</Typography>
            </Grid>
          </Grid>
          {objects.map(planetId => {
            const planet = chart.getPlanet(planetId);
            const sign = chart.getPlanetSign(planetId);
            const AsIcon = ObjectIcon[planetId];
            const SIcon = SignIcon[planet.sign];
            return (
              <StyledPlanetRow container key={planet.id} selected={selectedPlanet === planetId}>
                <Grid item xs={4}>
                  <AsIcon style={aspectIconStyle} />
                  {planet.name}{" "}
                  {planet.movement === RETROGRADE ? (
                    <RetrogradeIcon style={aspectIconStyle} />
                  ) : null}
                </Grid>
                <Grid item xs>
                  <SIcon style={aspectIconStyle} />
                  {sign.name} {planet.signlon}°
                </Grid>
                <Grid item xs sx={textRightStyle}>
                  {chart.getObjectHouseDisplay(planet)}
                </Grid>
              </StyledPlanetRow>
            );
          })}
        </CardContent>
      </Card>
    </Box>
  );
}
