import {createTheme} from "@mui/material";

const h = {
  fontFamily: ["Helvetica"].join(",")
};
const theme = createTheme({
  fontFamily: ["Helvetica"].join(","),
  typography: {
    h1: h,
    h2: h,
    h3: h,
    h4: h,
    h5: h,
    h6: h,
    fontFamily: ["Helvetica"].join(",")
  },
  shape: {
    borderRadius: 0,
    border: "1px solid #000000"
  },
  palette: {
    primary: {
      main: "#232428"
    }
    // text: {
    //   primary: "#FFFFFF",
    //   secondary: "#DDDDDD",
    //   disabled: "#888888"
    // },
    // background: {
    //   paper: "#333333",
    //   default: "#333333"
    // }
  }
});

theme.typography.h3 = {
  fontSize: '1.5rem',
  '@media (min-width:600px)': {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
  ...h
};
theme.typography.h4 = {
  fontSize: '1.4rem',
  '@media (min-width:600px)': {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.3rem',
  },
  ...h
};
theme.typography.h5 = {
  fontSize: '1.3rem',
  '@media (min-width:600px)': {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
  },
  ...h
};
export default theme;
