import axios from "axios";
import Cookies from "universal-cookie";
import MockAdapter from "axios-mock-adapter";

const cookies = new Cookies();

const CHART_DATA = {
  datetime: "1993-11-15T15:32:00Z",
  pos: {
    lat: -33.4488897,
    lon: -70.6692655
  },
  hsys: "Placidus",
  objects: [
    {
      id: "Sun",
      type: "Planet",
      lon: "233.29",
      sign: "Scorpio",
      signlon: "23.29",
      sign_element: "Water",
      sign_mode: "Fixed",
      house: 10,
      movement: "Direct"
    },
    {
      id: "Moon",
      type: "Planet",
      lon: "257.04",
      sign: "Sagittarius",
      signlon: "17.04",
      sign_element: "Fire",
      sign_mode: "Mutable",
      house: 11,
      movement: "Direct"
    },
    {
      id: "Mercury",
      type: "Planet",
      lon: "216.55",
      sign: "Scorpio",
      signlon: "6.55",
      sign_element: "Water",
      sign_mode: "Fixed",
      house: 10,
      movement: "Direct"
    },
    {
      id: "Venus",
      type: "Planet",
      lon: "218.22",
      sign: "Scorpio",
      signlon: "8.22",
      sign_element: "Water",
      sign_mode: "Fixed",
      house: 10,
      movement: "Direct"
    },
    {
      id: "Mars",
      type: "Planet",
      lon: "244.62",
      sign: "Sagittarius",
      signlon: "4.62",
      sign_element: "Fire",
      sign_mode: "Mutable",
      house: 10,
      movement: "Direct"
    },
    {
      id: "Jupiter",
      type: "Planet",
      lon: "211.12",
      sign: "Scorpio",
      signlon: "1.12",
      sign_element: "Water",
      sign_mode: "Fixed",
      house: 9,
      movement: "Direct"
    },
    {
      id: "Saturn",
      type: "Planet",
      lon: "323.93",
      sign: "Aquarius",
      signlon: "23.93",
      sign_element: "Air",
      sign_mode: "Fixed",
      house: 1,
      movement: "Direct"
    },
    {
      id: "Uranus",
      type: "Planet",
      lon: "289.23",
      sign: "Capricorn",
      signlon: "19.23",
      sign_element: "Earth",
      sign_mode: "Cardinal",
      house: 12,
      movement: "Direct"
    },
    {
      id: "Neptune",
      type: "Planet",
      lon: "288.96",
      sign: "Capricorn",
      signlon: "18.96",
      sign_element: "Earth",
      sign_mode: "Cardinal",
      house: 12,
      movement: "Direct"
    },
    {
      id: "Pluto",
      type: "Planet",
      lon: "235.34",
      sign: "Scorpio",
      signlon: "25.34",
      sign_element: "Water",
      sign_mode: "Fixed",
      house: 10,
      movement: "Direct"
    },
    {
      id: "North Node",
      type: "Moon Node",
      lon: "243.55",
      sign: "Sagittarius",
      signlon: "3.55",
      sign_element: "Fire",
      sign_mode: "Mutable",
      house: 10,
      movement: null
    },
    {
      id: "South Node",
      type: "Moon Node",
      lon: "63.55",
      sign: "Gemini",
      signlon: "3.55",
      sign_element: "Air",
      sign_mode: "Mutable",
      house: 4,
      movement: null
    }
  ],
  houses: [
    {
      id: 1,
      lon: "315.63",
      signlon: "15.63",
      condition: "Angular",
      gender: "Masculine"
    },
    {
      id: 2,
      lon: "339.09",
      signlon: "9.09",
      condition: "Succedent",
      gender: "Feminine"
    },
    {
      id: 3,
      lon: "7.02",
      signlon: "7.02",
      condition: "Cadent",
      gender: "Masculine"
    },
    {
      id: 4,
      lon: "39.46",
      signlon: "9.46",
      condition: "Angular",
      gender: "Feminine"
    },
    {
      id: 5,
      lon: "73.72",
      signlon: "13.72",
      condition: "Succedent",
      gender: "Masculine"
    },
    {
      id: 6,
      lon: "106.25",
      signlon: "16.25",
      condition: "Cadent",
      gender: "Feminine"
    },
    {
      id: 7,
      lon: "135.63",
      signlon: "15.63",
      condition: "Angular",
      gender: "Masculine"
    },
    {
      id: 8,
      lon: "159.09",
      signlon: "9.09",
      condition: "Succedent",
      gender: "Feminine"
    },
    {
      id: 9,
      lon: "187.02",
      signlon: "7.02",
      condition: "Cadent",
      gender: "Masculine"
    },
    {
      id: 10,
      lon: "219.46",
      signlon: "9.46",
      condition: "Angular",
      gender: "Feminine"
    },
    {
      id: 11,
      lon: "253.72",
      signlon: "13.72",
      condition: "Succedent",
      gender: "Masculine"
    },
    {
      id: 12,
      lon: "286.25",
      signlon: "16.25",
      condition: "Cadent",
      gender: "Feminine"
    }
  ],
  angles: [
    {
      id: "Asc",
      type: "Generic",
      lon: "315.63",
      sign: "Aquarius",
      signlon: "15.63",
      sign_element: "Air",
      sign_mode: "Fixed",
      house: 1,
      movement: null
    },
    {
      id: "MC",
      type: "Generic",
      lon: "219.46",
      sign: "Scorpio",
      signlon: "9.46",
      sign_element: "Water",
      sign_mode: "Fixed",
      house: 10,
      movement: null
    },
    {
      id: "Desc",
      type: "Generic",
      lon: "135.63",
      sign: "Leo",
      signlon: "15.63",
      sign_element: "Fire",
      sign_mode: "Fixed",
      house: 7,
      movement: null
    },
    {
      id: "IC",
      type: "Generic",
      lon: "39.46",
      sign: "Taurus",
      signlon: "9.46",
      sign_element: "Earth",
      sign_mode: "Fixed",
      house: 4,
      movement: null
    },
    {
      id: "Vertex",
      type: "Generic",
      lon: "86.47",
      sign: "Gemini",
      signlon: "26.47",
      sign_element: "Air",
      sign_mode: "Mutable",
      house: 5,
      movement: null
    }
  ],
  aspects: [
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "Mars"
      },
      type: "0",
      orb: "11.33"
    },
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "Saturn"
      },
      type: "90",
      orb: "0.64"
    },
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "Uranus"
      },
      type: "60",
      orb: "4.06"
    },
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "Neptune"
      },
      type: "60",
      orb: "4.33"
    },
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "Pluto"
      },
      type: "0",
      orb: "2.05"
    },
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "North Node"
      },
      type: "0",
      orb: "10.26"
    },
    {
      active: {
        id: "Sun"
      },
      passive: {
        id: "South Node"
      },
      type: "180",
      orb: "10.26"
    },
    {
      active: {
        id: "Moon"
      },
      passive: {
        id: "Saturn"
      },
      type: "60",
      orb: "6.89"
    },
    {
      active: {
        id: "Venus"
      },
      passive: {
        id: "Mercury"
      },
      type: "0",
      orb: "1.67"
    },
    {
      active: {
        id: "Jupiter"
      },
      passive: {
        id: "Mercury"
      },
      type: "0",
      orb: "5.43"
    },
    {
      active: {
        id: "Mercury"
      },
      passive: {
        id: "South Node"
      },
      type: "150",
      orb: "3.00"
    },
    {
      active: {
        id: "Venus"
      },
      passive: {
        id: "Jupiter"
      },
      type: "0",
      orb: "7.10"
    },
    {
      active: {
        id: "Mars"
      },
      passive: {
        id: "North Node"
      },
      type: "0",
      orb: "1.07"
    },
    {
      active: {
        id: "Mars"
      },
      passive: {
        id: "South Node"
      },
      type: "180",
      orb: "1.07"
    },
    {
      active: {
        id: "Jupiter"
      },
      passive: {
        id: "Saturn"
      },
      type: "120",
      orb: "7.19"
    },
    {
      active: {
        id: "Jupiter"
      },
      passive: {
        id: "South Node"
      },
      type: "150",
      orb: "2.43"
    },
    {
      active: {
        id: "Pluto"
      },
      passive: {
        id: "Saturn"
      },
      type: "90",
      orb: "1.41"
    },
    {
      active: {
        id: "Uranus"
      },
      passive: {
        id: "Neptune"
      },
      type: "0",
      orb: "0.26"
    }
  ]
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : "",
  headers: {
    Authorization: `Token ${cookies.get("auth_token")}`
  },
  withCredentials: true
});

// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    config.headers.Authorization = `Token ${cookies.get("auth_token")}`;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const delayedResponse = ({ data, timeout, code }) => {
  return config => {
    const { _ } = JSON.parse(config.data || "{}");
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([code, data]);
      }, timeout);
    });
  };
};

if (false && process.env.NODE_ENV === "development") {
  const mock = new MockAdapter(instance);
  mock
    .onGet("/geo-city/")
    .reply(200, [
      { display_name: "Santiago, Chile", lat: -33.4489, lon: -70.6693 }
    ]);
  // mock.onGet(/\/geo-city\//).reply(200, [{display_name: "Santiago, Chile", lat: -33.4489, lon: -70.6693}]);

  // mock post request to /build-chart/ without timeout
  mock.onGet("/build-chart/").reply(
    delayedResponse({
      data: CHART_DATA,
      timeout: 2000,
      code: 200
    })
  );

  // mock post request to /ask/question/ endpoint and waits 2 seconds before returning response
  let askCount = 0;
  mock.onPost("/ask/question/").passThrough();
  /* mock.onPost("/ask/question/").reply(config => {
    askCount++;
    const {question} = JSON.parse(config.data);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([200, {
          answer: "Mi consejo astrológico es buena suerte.",
          unsafe: false,
          quota_exceeded: askCount != 3 ? false : true,
          unavailable: false
        }]);
      }, 6000);
    });
  });*/
}

export default instance;
