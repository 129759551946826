import React, {useState} from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import reportImage from "assets/img/report_birthchart.png";
import GeoLocalizerInput from "../../components/Input/GeoLocalizerInput";
import Button from "@mui/material/Button";
import axios from "../../utils/axios";
import HiddenJs from "@mui/material/Hidden/HiddenJs";
import AppTextField from "../../components/Input/AppTextField";
import AppDatePicker from "../../components/Input/AppDatePicker";
import AppTimePicker from "../../components/Input/AppTimePicker";
import AppGeoLocalizerInput from "../../components/Input/AppGeoLocalizerInput";
import AppButton from "../../components/Button/AppButton";


export default function BirthChartReportView(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [submitting, setSubmitting] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationQuery, setQuery] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [product, setProduct] = useState("NATAL_CHART_REPORT");

  const handleChangeWrapper = fn => {
    return e => fn(e.target.value);
  };

  const handlePlaceOrder = async () => {
    setSubmitting(true);
    // Submits
    try {
      const response = await axios.post(`/payment/order/`, {
        name,
        email,
        date: date.format("YYYY-MM-DD"),
        time: time.format("HH:mm"),
        lat: location.lat,
        lon: location.lon,
        location: location.displayName,
        product
      });
      window.location = `${response.data.redirect_url}?token=${response.data.token}`;
    } catch (e) {
      alert("El servidor no responde. Por favor inténtalo nuevamente");
      setSubmitting(false);
    } finally {
    }
  };

  const disableSubmit =
    submitting || !name || !email || !date || !time || !location;
  return (
    <CenteredLayout>
      <Container maxWidth={"lg"}>
        <Card>
          <CardContent>
            <Box textAlign="left" p={matches ? 1 : 3}>
              <Grid container justify={"flex-start"} spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box textAlign="left">
                    <Typography variant="h4" color="textPrimary">
                      Reporte Carta Natal
                    </Typography>
                    <Box textAlign="center" marginTop={"10px"}/>
                    <Typography variant="body1" color="textPrimary">
                      Este informe natal es una guía personalizada que revela su
                      potencial único y su camino personal según los principios
                      de la astrología. Los planetas, signos, casas y aspectos
                      de su carta natal revelan información detallada sobre su
                      personalidad, emociones, aspiraciones y desafíos. La
                      interpretación de estos elementos puede servir como una
                      herramienta de autoconocimiento y crecimiento personal,
                      proporcionándole una mejor comprensión de sus instintos
                      naturales y patrones de comportamiento.
                    </Typography>
                  </Box>
                  <HiddenJs mdUp>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={8} md={6}>
                        <Box textAlign="center" marginTop={"30px"}/>
                        <div>
                          <img
                            src={reportImage}
                            alt="report"
                            style={{width: "100%"}}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </HiddenJs>
                  <Box textAlign="center" marginTop={"30px"}/>
                  <Typography variant="h6" color="textSecondary">
                    ¿Qué contiene el reporte?
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    - Configuración de la Carta Natal
                    <br/>
                    - Interpretación astrológica de la personalidad, amor y
                    relaciones personales, carrera y vocación, dinero y
                    abundancia, desarrollo espiritual
                    <br/>
                    - Análisis de configuraciones relevantes de la carta natal
                    <br/>
                    - Aspectos débiles a desarrollar y oportunidades de mejora
                    <br/>
                    - Alrededor de 25 páginas de contenido 100% personalizado
                    <br/>- Glosario de términos astrológicos
                  </Typography>
                  <Box textAlign="center" marginTop={"30px"}/>
                  <Typography variant="h5" color="textPrimary">
                    Datos personales
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    Ingresa los datos de la persona y un e-mail para enviar el
                    informe.
                  </Typography>
                  <Box textAlign="center" marginTop={"20px"}/>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <AppTextField
                        required
                        label="Nombre"
                        name={"name"}
                        value={name}
                        onChange={handleChangeWrapper(setName)}
                        eventTracker={{event: 'input_change', label: 'name - general_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AppTextField
                        required
                        label="E-mail"
                        name={"email"}
                        type="email"
                        value={email}
                        onChange={handleChangeWrapper(setEmail)}
                        eventTracker={{event: 'input_change', label: 'email - general_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppDatePicker
                        label="Fecha (DD/MM/YYYY)"
                        format="DD/MM/YYYY"
                        value={date}
                        onChange={setDate}
                        KeyboardButtonProps={{
                          "aria-label": "Cambiar fecha"
                        }}
                        disableFuture
                        required
                        eventTracker={{event: 'input_change', label: 'date - general_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppTimePicker
                        ampm={false}
                        label="Hora"
                        value={time}
                        onChange={setTime}
                        KeyboardButtonProps={{
                          "aria-label": "Cambiar hora"
                        }}
                        required
                        eventTracker={{event: 'input_change', label: 'time - general_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <AppGeoLocalizerInput
                        location={location}
                        query={locationQuery}
                        onQueryChange={setQuery}
                        onLocationChange={setLocation}
                        eventTracker={{event: 'input_change', label: 'location - general_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppButton
                        size={"large"}
                        onClick={handlePlaceOrder}
                        disabled={disableSubmit}
                        eventTracker={{
                          event: 'buy_product',
                          label: 'place_order - general_report_view',
                          category: 'Buy Interaction'
                        }}
                      >
                        Solicitar (Oferta $8.000 CLP)
                      </AppButton>
                    </Grid>
                  </Grid>
                </Grid>
                <HiddenJs smDown>
                  <Grid item xs={12} md={6}>
                    <div>
                      <img
                        src={reportImage}
                        alt="report"
                        style={{width: "100%"}}
                      />
                    </div>
                  </Grid>
                </HiddenJs>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </CenteredLayout>
  );
}
