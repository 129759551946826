import useQuery from "hooks/query/useQuery";
import BaseLayout from "components/Layout/BaseLayout";
import React, { useEffect, useState } from "react";
import NatalChartCls from "classes/NatalChart";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link,
  Typography
} from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useNatalChart from "../../hooks/axios/useNatalChart";
import "react-chat-elements/dist/main.css";
import Chat from "./ChatForm";
import useUsers from "../../hooks/useUsers";
import UserSelect from "../NatalChart/Fragment/UserSelect";
import ChartSummary from "./ChartSummary";
import { Alert } from "@mui/material";
import axios from "../../utils/axios";
import DialogActions from "@mui/material/DialogActions";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import AppButton from "../../components/Button/AppButton";

const _useNatalChart = (
  date,
  time,
  lat,
  lon,
  chiron,
  pholus,
  ceres,
  pallas,
  juno,
  vesta,
  nodes,
  minor_aspects,
  asc,
  mc,
  hsys
) => {
  const {
    loading,
    natalChart: data,
    removeItem: removeNatalChart
  } = useNatalChart({
    queryParams: {
      date,
      time,
      lat,
      lon,
      chiron,
      nodes,
      pholus,
      ceres,
      pallas,
      juno,
      vesta,
      minor_aspects,
      asc,
      mc,
      hsys
    },
    lazy: Boolean(!date)
  });
  const chart =
    !loading && data ? new NatalChartCls(date, time, lat, lon, data) : null;
  return { chart, loading, removeNatalChart };
};

export default function ChatView() {
  const query = useQuery();
  const gaEventTracker = useAnalyticsEventTracker({ category: 'User Interaction' });
  const history = useHistory();
  const gaBuyEventTracker = useAnalyticsEventTracker({ category: 'Buy Interaction' });
  const { selectedUser, setSelectedUser, users, setUsers } = useUsers();
  const {
    date,
    time,
    lat,
    lon,
    chiron,
    pholus,
    ceres,
    pallas,
    juno,
    vesta,
    nodes,
    minor_aspects,
    asc,
    mc,
    hsys
  } = query;
  const { loading: loadingChart, chart, removeNatalChart } = _useNatalChart(
    selectedUser?.date,
    selectedUser?.time,
    selectedUser?.lat,
    selectedUser?.lon,
    chiron,
    pholus,
    ceres,
    pallas,
    juno,
    vesta,
    nodes,
    minor_aspects,
    asc,
    mc,
    hsys
  );
  const [userDialogOpen, setUserDialogOpen] = React.useState(false);
  const [userInfoRequested, setUserInfoRequested] = React.useState(false);
  const [buyDialogOpen, setBuyDialogOpen] = React.useState(false);

  const [messageList, setMessageList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [quotaExceeded, setQuotaExceeded] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [buyFailed, setBuyFailed] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState(new Set([]));

  useEffect(() => {
    removeNatalChart();
    setMessageList([]);
  }, [selectedUser]);


  const addRecommendedProducts = products => {
    // creates a set from products list and merges it with the current recommended products set
    setRecommendedProducts(recommendedProducts => new Set([...recommendedProducts, ...products]));
  }

  const handleProductClick = (productInfo) => {
    // When a recommended product es clicked. Its information is onbtained from PRODUCTS_INFO, then redirect the user to the corresponding path
    // or link
    if (productInfo.path) {
      history.push({pathname:productInfo.path, search: `${queryString.stringify(selectedUser)}`});
    } else if(productInfo.url) {
      window.open(productInfo.url, '_blank');
    }
  }
  const addUserMessage = message => {
    setMessageList(messageList => [
      ...messageList,
      {
        position: "right",
        type: "text",
        title: (selectedUser && selectedUser["name"]) || "Anónim@",
        text: message
      }
    ]);
  };
  const addBotMessage = message => {
    setMessageList(messageList => [
      ...messageList,
      {
        position: "left",
        type: "text",
        title: "Astrall",
        text: message
      }
    ]);
  };

  const addBotTypingMessage = () => {
    setMessageList(messageList => [
      ...messageList,
      {
        position: "left",
        type: "text",
        title: "Astrall",
        text: "Typing..."
      }
    ]);
  };

  // replaces last message in messageList
  const replaceOrAddLastBotMessage = message => {
    setMessageList(messageList => {
      const newMessageList = [...messageList];
      const lastMessage = newMessageList.pop();

      const newMessage = {
        position: "left",
        type: "text",
        title: "Astrall",
        text: message
      };
      if (lastMessage.position === "left") {
        return [...newMessageList, newMessage];
      }
      return [...messageList, newMessage];
    });
  };

  // every 1 seconds changes last bot message to animate dots from 1 to 3
  const animateBotTyping = () => {
    return setInterval(() => {
      setMessageList(messageList => {
        const newMessageList = [...messageList];
        const lastMessage = newMessageList.pop();
        if (lastMessage.text === "Typing.") {
          lastMessage.text = "Typing..";
        } else if (lastMessage.text === "Typing..") {
          lastMessage.text = "Typing...";
        } else {
          lastMessage.text = "Typing.";
        }
        newMessageList.push(lastMessage);
        return newMessageList;
      });
    }, 1000);
  };

  const handleSend = async (userInput, resetInput) => {
    gaEventTracker('chat_message_submit', 'chat_view');
    if (!selectedUser) {
      setUserDialogOpen(true);
      return;
    } else if (
      (!selectedUser.date || !selectedUser.time) &&
      !userInfoRequested
    ) {
      setUserDialogOpen(true);
      return;
    }
    // setUnsafeAnswer(false);
    resetInput();
    setSubmitting(true);
    let interval;
    let timeout;
    try {
      addUserMessage(userInput);
      // delays interval typing animation with 3 seconds
      timeout = setTimeout(() => {
        addBotTypingMessage();
        interval = animateBotTyping();
      }, 2000);
      const response = await axios.post(`/ask/question/`, {
        question: userInput,
        date: selectedUser.date || null,
        time: selectedUser.time || null,
        lat: selectedUser.lat || null,
        lon: selectedUser.lon || null,
        user: {
          id: selectedUser.email,
          email: selectedUser.email,
          name: selectedUser.name,
          location: selectedUser.location || null,
          date: selectedUser.date || null,
          time: selectedUser.time || null
        }
      });
      if (response.data.quota_exceeded) {
        setQuotaExceeded(true);
        replaceOrAddLastBotMessage(
          "Lo siento, has superado el límite de consultas gratuitas de hoy. Para obtener más consultas, has click en el botón de abajo."
        );
      } else if (response.data.unavailable) {
        // setUnsafeAnswer(true);
      } else {
        addRecommendedProducts(response.data.products);
        replaceOrAddLastBotMessage(response.data.answer);
      }
      // removes interval
      // setAnswer(response.data.answer);
      // setSuccess(true);
    } catch (e) {
      // setUnsafeAnswer(true);
      // setAnswer("Servicio temporalmente no disponible. Inténtalo más tarde.");
      replaceOrAddLastBotMessage(
        "Lo siento, no puedo responder a tu pregunta en este momento. Inténtalo más tarde."
      );
    } finally {
      clearInterval(interval);
      clearTimeout(timeout);
      setSubmitting(false);
    }
  };

  const handleBuy = async product => {
    setBuyLoading(true);
    setBuyFailed(false);
    setTimeout(() => {
      setBuyLoading(false);
      setBuyFailed(true);
    }, 2000);
  };
  const timeIncludedInChart = (selectedUser && selectedUser["time"]) || false;
  return (
    <BaseLayout footer={true}>
      <Container maxWidth={"lg"} disableGutters>
        <Dialog open={buyDialogOpen} onClose={() => {
          setBuyDialogOpen(false);
          gaBuyEventTracker("close_product", "questions - chat_view");
        }}>
          <DialogTitle>Obtener más consultas astrológicas</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                  onClick={() => {
                    handleBuy("questions_3");
                    gaBuyEventTracker("buy_product", "questions_3 - chat_view");
                  }}
                >
                  3 consultas ($3.000)
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                  onClick={() => {
                    handleBuy("questions_5");
                    gaBuyEventTracker("buy_product", "questions_5 - chat_view");
                  }}
                >
                  5 consultas ($4.500)
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  fullWidth
                  onClick={() => {
                    handleBuy("questions_10");
                    gaBuyEventTracker("buy_product", "questions_10 - chat_view");
                  }}
                >
                  10 consultas ($8.000)
                </Button>
              </Grid>
              {buyLoading && (
                <Grid item xs={12} md={12}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <CircularProgress />
                  </Box>
                </Grid>
              )}
              {buyFailed && (
                <Grid item xs={12} md={12}>
                  <Typography color={"error"} align={"center"}>
                    No se pudo iniciar la compra. Inténtalo más tarde.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setBuyDialogOpen(false);
                gaBuyEventTracker("close_product", "questions - chat_view");
              }}
              variant={"contained"}
              color="secondary"
              size={"large"}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        <Box m={1}>
          <Grid container justify={"center"} spacing={3}>
            <Grid item xs={12}>
              <UserSelect
                users={users || []}
                selected={selectedUser}
                setSelected={setSelectedUser}
                open={userDialogOpen}
                setOpen={setUserDialogOpen}
                onClose={() => {
                  setUserDialogOpen(false);
                  setUserInfoRequested(true);
                  gaEventTracker("close_form", "user_form - chat_view");
                }}
              />
            </Grid>
            {chart && (
              <Grid item xs={12}>
                <ChartSummary
                  user={selectedUser}
                  chart={chart}
                  timeIncluded={selectedUser?.time}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {loadingChart && (
                <Box display={"flex"} justifyContent={"center"}>
                  <CircularProgress />
                </Box>
              )}
              {!loadingChart && chart && !timeIncludedInChart && (
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                      setUserDialogOpen(true);
                      gaEventTracker("open_form", "time_user_form - chat_view");
                      }}
                    >
                      Completar
                    </Button>
                  }
                >
                  Falta información de tu hora de nacimiento
                </Alert>
              )}
              {!loadingChart && !chart && (
                <Alert
                  severity="warning"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setUserDialogOpen(true);
                        gaEventTracker("open_form", "date_user_form - chat_view");
                      }}
                    >
                      Completar
                    </Button>
                  }
                >
                  Falta tu información de nacimiento. Astrall no podrá darte
                  respuestas personalizadas a tu carta natal sin tu información.
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="center">
                <Typography
                  variant={"caption"}
                  align={"center"}
                  color={"textSecondary"}
                >
                  TU CHAT CON ASTRALL
                </Typography>
              </Box>
              <Chat
                user={selectedUser}
                handleSend={handleSend}
                disabled={submitting}
                messages={messageList}
                hideInput={quotaExceeded}
                recommendedProducts={recommendedProducts}
                onProductClick={handleProductClick}
              />
            </Grid>
            {quotaExceeded && (
              <Grid item xs={12} md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setBuyDialogOpen(true);
                    gaBuyEventTracker("open_product", "questions - chat_view");
                  }}
                >
                  Comprar más consultas
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </BaseLayout>
  );
}
