// Compoonent that extends Material UI TextField
// It adds Google Analtics event tracking params to the input
import TextField from "@mui/material/TextField";
import React from "react";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";
import PropTypes from "prop-types";

const AppTextField = ({name, variant = "outlined", label, value, onChange, eventTracker, ...props}) => {
  const gaEventTracker = useAnalyticsEventTracker({ category: 'User Interaction' });

  const handleChange = (event) => {
    if (eventTracker)
      gaEventTracker(eventTracker.event, eventTracker.label);
    onChange(event);
  }
  return <TextField
    label={label}
    name={name}
    variant={variant}
    value={value}
    onChange={handleChange}
    fullWidth
    {...props}
  />
}

export default AppTextField;

AppTextField.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  eventTracker: PropTypes.shape({
    event: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
}