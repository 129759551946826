import React from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import { Card, CardContent, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";


export default function AboutUsView(props) {
  return (
    <CenteredLayout>
      <Container maxWidth={"lg"}>
        <Card>
          <CardContent>
            <Box textAlign="center">
              <Grid container justifyContent={"center"} spacing={3}>
                <Grid item xs={10}>
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Box textAlign="center">
                        <Typography variant="h3" color="textPrimary">
                          ¿Qué es Astrall?
                        </Typography>
                        <Box textAlign="center" marginTop={"10px"} />
                        <Typography variant="body1" color="textPrimary">
                          <strong>
                            Astrall nació como un proyecto para democratizar la
                            astrología
                          </strong>{" "}
                          y darle acceso a las personas interesadas en este
                          campo.
                          <br />
                          <br />
                          La misión de Astrall es que cualquier persona, incluso
                          aquellos sin los conocimientos necesarios, pueda
                          acceder, aprovechar y analizar las interpretaciones de
                          cualquiera de sus recursos astrológicos.
                        </Typography>
                      </Box>
                      <Box textAlign="center" marginTop={"30px"} />
                      <Box textAlign="center">
                        <Typography variant="h5" color="textPrimary">
                          ¿Quiénes somos?
                        </Typography>
                        <Box textAlign="center" marginTop={"10px"} />
                        <Typography variant="body1" color="textPrimary">
                          Mi nombre es Ednar Echeverría, informático y un
                          apasionado en la creación de proyectos que puedan
                          impactar positivamente la vida de las personas.
                          <br />
                          <br />
                          Este proyecto nació debido a una vieja relación que
                          tuve con una excelente astróloga, donde aprendí mucho
                          de esta maravillosa área.
                          <br />
                          <br />
                          <strong>
                            Aquí unifico mi conocimiento astrológico e
                            informático para intentar cubrir la necesidad de
                            algunas personas para descubrirse a sí mism@s y
                            desarrollarse a través de la astrología.
                          </strong>
                          <br />
                          <br />
                          Veo la astrología como una poderosa herramienta, que
                          debe ser respetada y bien utilizada. Y, a la vez,
                          entiendo su uso con fines recreativo que algunos le
                          dan. Al menos yo, soy un fiel creyente que si la luna
                          es capaz de elevar el mar, entonces no somos agenos a
                          la energía que otros enormes planetas pueden ejercer
                          sobre nosotros.
                        </Typography>
                      </Box>
                      <Box textAlign="center" marginTop={"30px"} />
                    </Grid>
                    <Grid item>
                      <Box textAlign="center">
                        <Typography variant="subtitle1">
                          ¿Quieres escribirme algo?
                        </Typography>
                        <Typography variant="body2">
                          <a href="mailto:ednar.echev@gmail.com">
                            ednar.echev@gmail.com
                          </a>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </CenteredLayout>
  );
}
