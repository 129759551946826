import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";
import Button from "@mui/material/Button";


const AppButton = ({onClick, eventTracker, ...props}) => {
  const gaEventTracker = useAnalyticsEventTracker({ category: eventTracker.category || 'User Interaction' });

  const handleClick = (event) => {
    if (eventTracker)
      gaEventTracker(eventTracker.event, eventTracker.label);
    onClick(event);
  }
  return <Button
    onClick={handleClick}
    variant={"contained"}
    color={"primary"}
    fullWidth
    {...props}
  />
}

export default AppButton;