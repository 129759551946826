import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React from "react";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import PropTypes from "prop-types";


const AppTimePicker = ({onChange, eventTracker, ...props}) => {
  const gaEventTracker = useAnalyticsEventTracker({ category: 'User Interaction' });

  const handleChange = (event) => {
    if (eventTracker)
      gaEventTracker(eventTracker.event, eventTracker.label);
    onChange(event);
  }
  return <TimePicker
    onChange={handleChange}
    inputVariant={"outlined"}
    sx={{width: '100%'}}
    {...props}
  />
}

export default AppTimePicker;

AppTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  eventTracker: PropTypes.shape({
    event: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
}