import React, { useState } from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import reportImage from "assets/img/counceling.png";
import Button from "@mui/material/Button";
import axios from "../../utils/axios";
import HiddenJs from "@mui/material/Hidden/HiddenJs";

export default function CouncelingView(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [submitting, setSubmitting] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationQuery, setQuery] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [product, setProduct] = useState("COUNCELING");

  const handleChangeWrapper = fn => {
    return e => fn(e.target.value);
  };

  const handlePlaceOrder = async () => {
    setSubmitting(true);
    // Submits
    try {
      const response = await axios.post(`/payment/order/`, {
        name,
        email,
        date: date.format("YYYY-MM-DD"),
        time: time.format("HH:mm"),
        lat: location.lat,
        lon: location.lon,
        location: location.displayName,
        product
      });
      window.location = `${response.data.redirect_url}?token=${response.data.token}`;
    } catch (e) {
      alert("El servidor no responde. Por favor inténtalo nuevamente");
      setSubmitting(false);
    } finally {
    }
  };

  const disableSubmit =
    submitting || !name || !email || !date || !time || !location;
  return (
    <CenteredLayout>
      <Container maxWidth={"lg"}>
        <Card>
          <CardContent>
            <Box textAlign="left" p={matches ? 1 : 3}>
              <Grid container justify={"flex-start"} spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box textAlign="left">
                    <Typography variant="h4" color="textPrimary">
                      Consejería profesional
                    </Typography>
                    <Box textAlign="center" marginTop={"10px"} />
                    <Typography variant="body1" color="textPrimary">
                      Accede a profesionales capacitados y con vasta experiencia
                      para escuchar atentamente tus preocupaciones, comprender
                      tus desafíos y ayudarte a encontrar soluciones efectivas.
                      <br />
                      <br />A través de una combinación de técnicas y enfoques
                      probados,{" "}
                      <strong>
                        te ayudaremos a desbloquear tus fortalezas internas y a
                        establecer metas claras y alcanzables.
                      </strong>
                      <br />
                      <br />
                      No importa cuál sea tu dolor emocional;{" "}
                      <strong>ansiedad por el futuro</strong>,{" "}
                      <strong>dificultades con tu pareja</strong>,{" "}
                      <strong>baja autoestima</strong> o{" "}
                      <strong>falta de motivación</strong>. Nuestras sesiones
                      con expertos están diseñadas para adaptarse a tus
                      necesidades específicas.
                      <br />
                      <br />
                      No tienes que cargar con tus dolores emocionales en
                      silencio. Permítenos ayudarte a liberarte de ellos y vivir
                      una vida plena y feliz.
                    </Typography>
                  </Box>
                  <HiddenJs mdUp>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={8} md={6}>
                        <Box textAlign="center" marginTop={"30px"} />
                        <div>
                          <img
                            src={reportImage}
                            alt="report"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </HiddenJs>
                  <Box textAlign="center" marginTop={"20px"} />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant={"contained"}
                        color={"primary"}
                        size={"large"}
                        href="https://ig.me/m/astrall.cl?"
                        // onClick={handlePlaceOrder}
                        // disabled={disableSubmit}
                      >
                        Solicitar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <HiddenJs smDown>
                  <Grid item xs={12} md={6}>
                    <div>
                      <img
                        src={reportImage}
                        alt="report"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                </HiddenJs>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </CenteredLayout>
  );
}
