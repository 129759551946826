import React, {useState} from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import reportImage from "assets/img/report_love.png";
import axios from "../../utils/axios";
import HiddenJs from "@mui/material/Hidden/HiddenJs";
import MenuItem from "@mui/material/MenuItem";
import AppButton from "../../components/Button/AppButton";
import AppGeoLocalizerInput from "../../components/Input/AppGeoLocalizerInput";
import AppTimePicker from "../../components/Input/AppTimePicker";
import AppDatePicker from "../../components/Input/AppDatePicker";
import AppTextField from "../../components/Input/AppTextField";


export default function LoveReportView(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [submitting, setSubmitting] = useState(false);
  const [location, setLocation] = useState(null);
  const [locationQuery, setQuery] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [product, setProduct] = useState("LOVE_REPORT");
  const [reportFormat, setReportFormat] = useState("NORMAL");

  const handleChangeWrapper = fn => {
    return e => fn(e.target.value);
  };

  const handlePlaceOrder = async () => {
    setSubmitting(true);
    // Submits
    try {
      const response = await axios.post(`/payment/order/`, {
        name,
        email,
        date: date.format("YYYY-MM-DD"),
        time: time.format("HH:mm"),
        lat: location.lat,
        lon: location.lon,
        location: location.displayName,
        product,
        format: reportFormat
      });
      window.location = `${response.data.redirect_url}?token=${response.data.token}`;
    } catch (e) {
      alert("El servidor no responde. Por favor inténtalo nuevamente");
      setSubmitting(false);
    } finally {
    }
  };

  const getPrice = () => {
    if (reportFormat === "SHORT") return "4.000";
    if (reportFormat === "FULL") return "25.000";
    return "8.000";
  };

  const disableSubmit =
    submitting || !name || !email || !date || !time || !location;
  const price = getPrice();
  return (
    <CenteredLayout>
      <Container maxWidth={"lg"}>
        <Card>
          <CardContent>
            <Box textAlign="left" p={matches ? 1 : 3}>
              <Grid container justify={"flex-start"} spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box textAlign="left">
                    <Typography variant="h4" color="textPrimary">
                      Reporte Amor y Relaciones de pareja
                    </Typography>
                    <Box textAlign="center" marginTop={"10px"}/>
                    <Typography variant="body1" color="textPrimary">
                      Al desentrañar los misterios de la carta natal, abrimos
                      puertas a nuevas perspectivas, especialmente en el terreno
                      del amor y las relaciones íntimas. Este informe, centrado
                      en estos aspectos, no solo arrojará luz sobre tu
                      personalidad y compatibilidad amorosa, sino que también te
                      ayudará a identificar desafíos y ofrecerá recomendaciones
                      para cultivar y desarrollar relaciones más profundas y
                      satisfactorias.
                    </Typography>
                  </Box>
                  <HiddenJs mdUp>
                    <Grid container justify="center">
                      <Grid item xs={12} sm={8} md={6}>
                        <Box textAlign="center" marginTop={"30px"}/>
                        <div>
                          <img
                            src={reportImage}
                            alt="report"
                            style={{width: "100%"}}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </HiddenJs>
                  <Box textAlign="center" marginTop={"30px"}/>
                  <TextField
                    select
                    fullWidth
                    label="Tipo de reporte"
                    variant="outlined"
                    value={reportFormat}
                    onChange={handleChangeWrapper(setReportFormat)}
                  >
                    <MenuItem value={""} disabled>
                      Selecciona una opción...
                    </MenuItem>
                    <MenuItem value={"SHORT"}>
                      Corto (4 páginas personalizadas)
                    </MenuItem>
                    <MenuItem value={"NORMAL"}>
                      Normal (25 páginas personalizadas)
                    </MenuItem>
                  </TextField>
                  <Box textAlign="center" marginTop={"10px"}/>
                  <Typography variant="h6" color="textSecondary">
                    ¿Qué contiene el reporte?
                  </Typography>
                  {reportFormat === "NORMAL" && (
                    <Typography variant="body1" color="textSecondary">
                      - Configuración de la Carta Natal
                      <br/>
                      - Interpretación y análisis de diferentes áreas amorosas:
                      personalidad, compatibilidad/incompatibilidad,
                      pensamientos y comunicación, emociones y seguridad
                      emocional, compromiso y asociaciones, pasión y sexualidad
                      <br/>
                      - Principales desafíos amorosas y recomendaciones para
                      desarrollar sus relaciones en las diferentes áreas
                      señaladas
                      <br/>
                      - Alrededor de 25 páginas de contenido 100% personalizado
                      <br/>
                    </Typography>
                  )}
                  {reportFormat === "SHORT" && (
                    <Typography variant="body1" color="textSecondary">
                      - Análisis de personalidad en las relaciones
                      <br/>
                      - Compatibilidad zodiacal y de personalidad
                      <br/>
                      - Consejos para superar desafíos y potenciar tus
                      relaciones
                      <br/>
                      - Alrededor de 4 páginas de contenido personalizado
                      <br/>
                    </Typography>
                  )}
                  <Box textAlign="center" marginTop={"30px"}/>
                  <Typography variant="h5" color="textPrimary">
                    Datos personales
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    Ingresa los datos de la persona y un e-mail para enviar el
                    informe.
                  </Typography>
                  <Box textAlign="center" marginTop={"20px"}/>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <AppTextField
                        required
                        label="Nombre"
                        name={"name"}
                        value={name}
                        onChange={handleChangeWrapper(setName)}
                        eventTracker={{event: 'input_change', label: 'name - love_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AppTextField
                        required
                        label="E-mail"
                        type="email"
                        name={"email"}
                        value={email}
                        onChange={handleChangeWrapper(setEmail)}
                        eventTracker={{event: 'input_change', label: 'email - love_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppDatePicker
                        label="Fecha (DD/MM/YYYY)"
                        format="DD/MM/YYYY"
                        value={date}
                        onChange={setDate}
                        KeyboardButtonProps={{
                          "aria-label": "Cambiar fecha"
                        }}
                        disableFuture
                        required
                        eventTracker={{event: 'input_change', label: 'date - love_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <AppTimePicker
                        ampm={false}
                        label="Hora"
                        value={time}
                        onChange={setTime}
                        KeyboardButtonProps={{
                          "aria-label": "Cambiar hora"
                        }}
                        required
                        eventTracker={{event: 'input_change', label: 'time - love_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <AppGeoLocalizerInput
                        location={location}
                        query={locationQuery}
                        onQueryChange={setQuery}
                        onLocationChange={setLocation}
                        eventTracker={{event: 'input_change', label: 'location - love_report_view'}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppButton
                        size={"large"}
                        onClick={handlePlaceOrder}
                        disabled={disableSubmit}
                        eventTracker={{
                          event: 'buy_product',
                          label: 'place_order - love_report_view',
                          category: 'Buy Interaction'
                        }}
                      >
                        Solicitar (Oferta ${price} CLP)
                      </AppButton>
                    </Grid>
                  </Grid>
                </Grid>
                <HiddenJs smDown>
                  <Grid item xs={12} md={6}>
                    <div>
                      <img
                        src={reportImage}
                        alt="report"
                        style={{width: "100%"}}
                      />
                    </div>
                  </Grid>
                </HiddenJs>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </CenteredLayout>
  );
}
