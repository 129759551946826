import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { MOON, SUN } from "../../utils/constants";
import SignIcon from "../../components/icons/SignIcon";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ChartDetailsDialog from "./ChartDetailsDialog";
import styled from "@emotion/styled";
import useAnalyticsEventTracker from "../../hooks/ga/useAnalyticsEventTracker";

const StyledLabel = styled('div')(({ theme }) => ({
  fontSize: "1em",
  fontWeight: "500"
}));

const StyledTitleLink = styled('h6')(({ theme }) => ({
  fontSize: 12,
  color: "#757575"
}));

export default function ChartSummary({ user, chart, timeIncluded }) {
  const [chartDialogOpen, setChartDialogOpen] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker({ category: 'User Interaction' });

  const sunSign = chart.getPlanetSign(SUN);
  const moonSign = chart.getPlanetSign(MOON);
  const ascSign = chart.getAscendantSign();
  const SunSignIcon = SignIcon[sunSign.id];
  const MoonSignIcon = SignIcon[moonSign.id];
  const AscSignIcon = SignIcon[ascSign.id];
  const colWith = timeIncluded ? 4 : 6;
  return (
    <Box>
      <ChartDetailsDialog
        user={user}
        chart={chart}
        open={chartDialogOpen}
        onClose={() => {
          setChartDialogOpen(false);
          gaEventTracker('close_dialog', 'natal_chart_summary - chat_view');
        }}
      />
      <Paper>
        <Box p={2}>
          <Grid container alignItems={"center"}>
            <Grid item xs={true}>
              <Typography variant="overline" color="textPrimary">
                Resumen Carta Natal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={colWith} sx={{textAlign: "center"}}>
                  <StyledTitleLink>Sol</StyledTitleLink>
                  <StyledLabel>
                    <SunSignIcon height={12} width={12} /> {sunSign.name}
                  </StyledLabel>
                </Grid>
                <Grid item xs={colWith} sx={{textAlign: "center"}}>
                  <StyledTitleLink>Luna</StyledTitleLink>
                  <StyledLabel>
                    <MoonSignIcon height={12} width={12} /> {moonSign.name}
                  </StyledLabel>
                </Grid>
                {timeIncluded && (
                  <Grid item xs={colWith} sx={{textAlign: "center"}}>
                    <StyledTitleLink>Ascendente</StyledTitleLink>
                    <StyledLabel>
                      <AscSignIcon height={12} width={12} /> {ascSign.name}
                    </StyledLabel>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* Box with centered button*/}
              <Box display={"flex"} justifyContent={"center"} mt={1}>
                <Button
                  variant={"text"}
                  size={"small"}
                  color={"primary"}
                  onClick={() => {
                    setChartDialogOpen(true);
                    gaEventTracker('open_dialog', 'natal_chart_summary - chat_view')
                  }}
                >
                  Ver Carta Natal
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
