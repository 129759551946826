import React from "react";
import CenteredLayout from "components/Layout/CenteredLayout";
import Container from "@mui/material/Container";

import NatalChartSection from "./NatalChartSection";
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import useAnalyticsEventTracker from "hooks/ga/useAnalyticsEventTracker";
import LandingCarousel from "./LandingCarousel";


export default function LandingView(props) {
  const gaEventTracker = useAnalyticsEventTracker({ category: 'User Interaction' });

  const history = useHistory();
  return (
    <CenteredLayout>

      <LandingCarousel />

    </CenteredLayout>
  );
}
