import React from "react";

import {makeStyles, styled} from "@mui/material";

// Core
import Footer from "../Footer/Footer";
import Navbar from "../Narbar/Navbar";
import PropTypes from "prop-types";

const StyledLayoutRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "calc(100vh-56px)"
}));

const StyledLayoutBody = styled("div")(({ theme, hasFooter }) => ({
  minHeight: hasFooter ? "calc(100vh - 176px)" : "calc(100vh - 56px)",
}));

export default function BaseLayout({ children, footer }) {
  return (
    <StyledLayoutRoot>
      <Navbar />
      <StyledLayoutBody hasFooter={footer}>
        {children}
      </StyledLayoutBody>
      {footer && <Footer />}
    </StyledLayoutRoot>
  );
}

BaseLayout.propTypes = {
  footer: PropTypes.bool
};

BaseLayout.defaultProps = {
  footer: true
};
